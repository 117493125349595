import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect, useRef } from 'react';
import { getAttributes as fetchAttributes, getCategories as fetchCategories } from '@/lib/Definitions';
import DesktopMenuItem from './DesktopMenuItem';
import MobileMenuItem from './MobileMenuItem';
import { useCartsStore } from '@/stores/useCartsStore';
import Cart from '../Cart';
import { useUserStore } from '@/stores/useUserStore';
import axios from 'axios';
import { User } from '@/stores/initialState';
import Search from './Search';
import TopBanner from './TopBanner';
import { useCategoriesStore } from '@/stores/useCategoriesStore';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { useRouter } from 'next/router';


const Header = () => {
  const router = useRouter();
  const [isSyncing, setSyncing] = useState(false);
  const [isSyncingCategory, setSyncingCategory] = useState(false);
  const [isSyncingAttribute, setSyncingAttribute] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const { carts, getCarts } = useCartsStore();
  const { userInfo, updateUser, getUser } = useUserStore();
  const { updateCategories, getCategories } = useCategoriesStore();
  const { updateAttributes, getAttributes } = useAttributesStore();
  const isAuthGuestRef = useRef(false);
  const [showCart, setShowCart] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());
  const [categoriesData, setCategoriesData] = useState(getCategories());
  const [attributesData, setAttributesData] = useState(getAttributes());

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if ((categoriesData.data.length === 0 || categoriesData.update_date === null || categoriesData.update_date < Date.now() - 600000  ||
      attributesData.data.length === 0 || attributesData.update_date === null || attributesData.update_date < Date.now() - 600000) && !isSyncing) {
      setSyncing(!isSyncing);
      setSyncingCategory(true);
      setSyncingAttribute(true);

      fetchCategories()
        .then((resp) => {
          categoriesData.update_date = Date.now();
          categoriesData.data = resp;
          updateCategories(categoriesData);

          setCategoriesData(categoriesData);

          setSyncingCategory(!isSyncingCategory);
        });

      fetchAttributes()
        .then((resp) => {
          attributesData.update_date = Date.now();
          attributesData.data = resp;
          updateAttributes(attributesData);

          setAttributesData(attributesData);

          setSyncingAttribute(!isSyncingAttribute);
        });
    }
  }, [attributesData, categoriesData, isSyncing, isSyncingAttribute, isSyncingCategory, updateAttributes, updateCategories])

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const show_cart_info = () => {
    setShowCart(true)
  }

  useEffect(() => {
    const authGuest = async () => {
      const data = {
        "username": userData.uuid,
        "logintype": "guest",
        "action": "register",
      }

      try {
        const response = await axios.post(
          '/api/auth',
          data
        )

        const user: User = {
          user_id: response.data.user_id,
          email: response.data.email,
          level: response.data.membership_level,
          token: response.data.token,
          refresh_token: response.data.refresh_token,
          barcode: '',
          mooimom_point: 0,
          name: '',
          phone: '',
          referral_code: '',
          uuid: userData.uuid,
          country_code: '',
          birthday: null,
          gender: ''
        }

        updateUser(user);
        setUserData(user);
      } catch (error) {

      } finally {
        isAuthGuestRef.current = false;
      }
    }

    if (!isAuthGuestRef.current && userData.user_id === undefined) {
      isAuthGuestRef.current = true;
      authGuest()
    }
  }, [updateUser, userData])

  return (
    <div id="menu" className="sticky z-[50] top-0 bg-defaultBg">
      <TopBanner />
      <div className="container hidden grid-cols-12 md:grid">
        <div className="col-span-2 py-4">
          <Link href="/">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo.png"}
              alt="green parenting logo"
              quality={100}
              width={577}
              height={214}
              className="max-w-[60%]"
            />
          </Link>
        </div>
        <div className="flex items-center col-span-8 pl-9">
          <div className="grid w-full h-full grid-cols-4">
            {categoriesData.data.filter((category) => category.type === 'A').map((category, index) => (
              <DesktopMenuItem
                key={index}
                bundle_category={category.bundle_category}
                id={category.id}
                image_alt={category.image_alt}
                desktop_image_banner={category.desktop_image_banner}
                mobile_image_banner={category.mobile_image_banner}
                product_banner={category.product_banner}
                image_icon={category.image_icon}
                meta={category.meta}
                name={category.name}
                promotion={category.promotion}
                slug={category.slug}
                subcategory={category.subcategory}
                type={category.type}
                link={category.link}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-end col-span-2 py-4">
          <div className="flex items-center">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/search.png"}
              alt="search icon"
              quality={100}
              width={24}
              height={24}
              className="cursor-pointer"
              onClick={() => setShowSearch(true)}
            />
          </div>
          <div
            className="relative flex items-center ml-6 cursor-pointer"
            onClick={show_cart_info}
          >
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cart.png"}
              alt="cart icon"
              quality={100}
              width={24}
              height={24}
              className=""
            />
            {cartsData.data.length > 0 && (
              <div className="absolute z-10 w-4 h-4 text-xs text-center text-white bg-black rounded-full top-1 -right-1">{cartsData.data.length}</div>
            )}
          </div>
          <Link href={userData.level === "none" ? "/login" : "/user"} className="flex items-center ml-6">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/user.png"}
              alt="user icon"
              quality={100}
              width={24}
              height={24}
              className=""
            />
          </Link>
        </div>
      </div>
      <div className="container shadow-[0_0_8px_0_rgba(0,0,0,0.15)] md:hidden">
        <div className="flex items-center h-[60px] max-h-[60px] py-2">
          <div className="flex-none">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/menu.png"}
              alt="menu icon"
              quality={100}
              width={24}
              height={24}
              className="min-w-[20px]"
              onClick={toggleMenu}
            />
          </div>
          <div className="ml-[40px] grow flex items-center justify-center">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo.png"}
              alt="greenparenting logo"
              quality={100}
              width={577}
              height={214}
              className="max-w-[50%] max-h-[50%]"
              onClick={() => router.push("/")}
            />
          </div>
          <div className="flex-none">
            <div className="flex">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/search.png"}
                alt="search icon"
                quality={100}
                width={24}
                height={24}
                className="cursor-pointer"
                onClick={() => setShowSearch(true)}
              />
              <div
                className="relative ml-4"
                onClick={show_cart_info}
              >
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cart.png"}
                  alt="cart icon"
                  quality={100}
                  width={24}
                  height={24}
                  className=""
                />
                {cartsData.data.length > 0 && (
                  <div className="absolute z-10 w-4 h-4 text-xs text-center text-white bg-black rounded-full -top-[2px] -right-1">{cartsData.data.length}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`fixed inset-0 bg-black opacity-50 ${isOpen ? 'block' : 'hidden'}`} onClick={toggleMenu}></div>
        <div
          className={`categories absolute w-[90%] z-[50] bg-defaultBg top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 left-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="container">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cancel.svg"}
              alt="close icon"
              width={24}
              height={24}
              quality={100}
              onClick={toggleMenu}
              className="my-8 cursor-pointer"
            />
          </div>
          <MobileMenuItem categories={categoriesData.data} />
          <div className="py-4 z-[55] bottom-0 border-t border-border left-0 right-0 fixed w-full">
            <Link href={userData.level === "none" ? "/login" : "/user"}>
              <div className="container flex items-center py-2">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/user.png"}
                  alt="user icon"
                  quality={100}
                  width={24}
                  height={24}
                  className=""
                />
                <p className="ml-[10px] text-sm text-primary font-dmsans font-normal">Account</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Cart isVisible={showCart} setIsVisible={setShowCart} />
      <Search isVisible={showSearch} setIsVisible={setShowSearch} />
    </div>
  )
}

export default Header
