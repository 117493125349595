import { useCallback, useEffect, useState } from "react";
import Image from 'next/image';
import axios from "axios";
import _ from 'lodash';
import { MinProductItemProps } from "../Product/constants";
import Link from "next/link";


interface SearchProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const Search = (props: SearchProps) => {
  const { isVisible, setIsVisible } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<Array<MinProductItemProps>>([]);
  const [isSearched, setIsSearched] = useState(false);

  useEffect(() => {
    setIsOpen(isVisible)
  }, [setIsOpen, isVisible])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    delayedSearch(value);
  }

  const show_search_result = () => {
    if (searchResult.length > 0) {
      return searchResult.map((product, index) => {
        if (product.product_variant_list.length === 0 || product.product_variant_list[0].cart_image === null || product.product_variant_list[0].cart_image.length === 0) {
          return (<></>);
        } else {
          return (
            <Link href={"/product/" + product.slug} key={index}>
              <div className={`${index > 0 ? "mt-4" : ""} flex items-center pr-4`}>
                <Image
                  src={product.product_variant_list[0].cart_image}
                  alt={product.name + " image"}
                  width={80}
                  height={80}
                  quality={100}
                />
                <p className="ml-4 text-sm text-primary font-dmsans">{product.name}</p>
              </div>
            </Link>
          );
        }
      });
    } else if (isSearched && searchTerm.length > 0) {
      return (
        <div className="w-full">
          <div className="flex justify-center">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cry-human.svg"}
              alt="crying human icon"
              width={80}
              height={80}
              quality={100}
            />
          </div>
          <p className="text-center text-base text-primary font-dmsans font-medium mt-4">No results could be found.</p>
          <button
            className="w-full py-4 px-12 bg-primary mt-[120px] text-base text-white font-dmsans font-medium text-center"
            onClick={() => setSearchTerm("")}
          >
            START NEW SEARCH
          </button>
        </div>
      )
    } else {
      return (<></>);
    }
  }

  const delayedSearch = useCallback(
    _.debounce(async (value: string) => {
      try {
        const response = await axios.get('/api/search', {
          params: { search: value },
        });

        if (response.data.total_count > 0) {
          setSearchResult(response.data.product_list);
        } else {
          setSearchResult([]);
        }

        setIsSearched(true);
      } catch (error) {
        console.log('Error searching:', error);
      }
    }, 1000),
    []
  );

  return (
    <>
      <div
        className={`${isOpen ? "fixed left-0 w-screen h-screen top-0 right-0 bottom-0 bg-black opacity-30 z-[54]" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsVisible(!isVisible);
        }}
      ></div>
      <div
        className={`fixed w-[90%] md:w-[468px] z-[55] bg-defaultBg top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 -right-[90%] md:-right-[468px] py-12 md:py-8 px-6 ${isOpen ? '-translate-x-full' : 'translate-x-0'}`}
      >
        <div className="flex justify-end">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cancel.svg"}
            alt="close icon"
            width={24}
            height={24}
            quality={100}
            onClick={() => {
              setIsOpen(!isOpen);
              setIsVisible(!isVisible);
            }}
            className="cursor-pointer"
          />
        </div>
        <input
          type="text"
          className="text-sm text-black w-full text-center font-dmsans py-2 bg-defaultBg focus:outline-none"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
        />
        <hr className="border-t-[1px] border-solid border-border my-4 md:my-8" />
        <div className="w-full my-8 overflow-y-auto size-guide-height">
          {show_search_result()}
        </div>
      </div>
    </>
  )
}

export default Search
