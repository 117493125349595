interface BrandNameProps {
  brand_name: string;
}

const BrandName = (props: BrandNameProps) => {

  return (
    <div className="mt-4 md:mt-8">
      <div className="text-sm text-primary font-dmsans font-bold">{props.brand_name}</div>
      <hr className="border-t-[1px] border-solid border-border mt-2 w-[25px]" />
    </div>
  )
}

export default BrandName
