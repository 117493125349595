import Image from 'next/image';
import { AddToCartProductItemProps, ProductVariantItemProps } from './constants';
import Attribute from './Attribute';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { useCartsStore } from '@/stores/useCartsStore';
import { event } from 'nextjs-google-analytics';
import { getNewToken } from '@/lib/Definitions';
import * as Dialog from '@radix-ui/react-dialog';


const AddToCart = (props: AddToCartProductItemProps) => {
  const { product_code, name, image, setShowCart, action, open, setOpen } = props;
  const { userInfo, getUser, updateUser } = useUserStore();
  const { carts, getCarts, updateCarts } = useCartsStore();
  const [qty, setQty] = useState(props.action === "add" ? 1 : props.qty);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(props.variant);
  const [displayImage, setDisplayImage] = useState(image);
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());

  const minus_qty = () => {
    if (variant !== null && variant !== undefined && variant.qty > 0 && qty > 1) {
      setQty(qty - 1);
    }
  }

  const plus_qty = () => {
    if (variant !== null && variant !== undefined && variant.qty > 0 && qty < variant.qty) {
      setQty(qty + 1);
    }
  }

  const add_to_cart = async () => {
    if (variant !== null) {
      const data = {
        product_id: props.id,
        product_variant_id: variant.variant_id,
        qty: qty
      }

      try {
        const response = await axios.post(
          '/api/cart',
          data,
          { headers: { Authorization: 'Bearer ' + userData.token } }
        )

        event("add_to_cart", {
          value: variant.sales_price,
          currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
          items: [
            {
              item_id: props.product_code,
              item_name: props.name,
              item_variant: variant.product_code,
              brand: props.brand,
              price: variant.special_price !== 0 ? variant.special_price : props.special_price,
              quantity: qty,
              image: variant.cart_image
            }
          ]
        });

        const findIndex = cartsData.data.findIndex(cart => cart.id === response.data.id)

        if (findIndex === -1) {
          cartsData.data.push({
            id: response.data.id,
            product_id: response.data.product_id,
            product_code: props.product_code,
            product_variant_id: response.data.product_variant_id,
            qty: response.data.qty,
            brand: props.brand,
            image: variant.cart_image,
            image_alt: props.name + ' image',
            msrp: props.msrp,
            sales_price: variant.sales_price,
            special_price: variant.special_price !== 0 ? variant.special_price : props.special_price,
            slug: props.slug,
            product_variant_list: props.product_variant_list,
            attribute_value_list: props.attribute_value_list,
            name: props.name
          })
        } else {
          cartsData.data[findIndex].qty = response.data.qty;
          cartsData.data[findIndex].product_variant_id = response.data.product_variant_id;
          cartsData.data[findIndex].image = variant.cart_image;
        }

        cartsData.update_date = Date.now();

        updateCarts(cartsData);
        setCartsData(cartsData);
        setShowCart(true);
        setOpen(false);
      } catch (error: any) {
        if (error.response.data.error === "Invalid credentials") {
          const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

          if (response !== "") {
            userData.token = response.token;
            userData.refresh_token = response.refresh_token;
            updateUser(userData);
            add_to_cart();
          }
        }
      }
    }
  }

  const update_to_cart = async () => {
    if (variant != null) {
      const data = {
        variant_id: variant.variant_id,
        qty: qty
      }

      try {
        const response = await axios.put(
          '/api/cart/' + props.id,
          data,
          { headers: { Authorization: 'Bearer ' + userData.token } }
        )

        if (response.data.status === "ok") {
          event("update_cart", {
            value: variant.sales_price,
            currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
            items: [
              {
                item_id: props.product_code,
                item_name: props.name,
                item_variant: variant.product_code,
                brand: props.brand,
                price: variant.special_price !== 0 ? variant.special_price : props.special_price,
                quantity: qty,
                image: variant.cart_image
              }
            ]
          });

          const idx = cartsData.data.findIndex(cart => cart.id === props.id);

          if (idx > -1) {
            cartsData.data[idx].qty = qty;
            cartsData.data[idx].product_variant_id = variant.variant_id;
            cartsData.data[idx].image = variant.cart_image;
            cartsData.update_date = Date.now();
            updateCarts(cartsData);
            setCartsData(cartsData);
          }
        }
      } catch (error: any) {
        if (error.response.data.error === "Invalid credentials") {
          const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

          if (response !== "") {
            userData.token = response.token;
            userData.refresh_token = response.refresh_token;
            updateUser(userData);
            update_to_cart();
          }
        }
      } finally {
        setOpen(false);
      }
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    if (variant !== null && variant !== undefined) {
      setDisplayImage(variant.cart_image);
    }
  }, [variant])

  useEffect(() => {
    setVariant(props.variant);
  }, [props.variant])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-overlay md:bg-overlayMd bg-opacity-50 md:bg-opacity-40 z-[60]" />
        <Dialog.Content
          className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[65] h-fit max-h-1/2 outline-none focus:ring-transparent"
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <Dialog.Title />
          <Dialog.Description />
          <div className="md:hidden rounded-t-lg bg-defaultBg p-8">
            <div className="flex justify-end mb-2">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cancel.svg"}
                alt="close icon"
                width={24}
                height={24}
                quality={100}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-start space-x-4">
              <Image
                src={displayImage}
                width={800}
                height={800}
                alt={name + " gallery"}
                quality={100}
                className="w-[90px]"
              />
              <div className="">
                <div className="text-sm text-border font-dmsans font-normal">{product_code}</div>
                <div className="text-base text-primary font-dmsans font-bold leading-6 mt-2">{name}</div>
              </div>
            </div>
            <div className="overflow-y-auto">
              <Attribute
                product_code={props.product_code}
                attribute_value_list={props.attribute_value_list}
                product_variant_list={props.product_variant_list}
                attributes={props.attributes}
                style="popup"
                variant={variant}
                setVariant={setVariant}
              />
              {variant !== null && variant !== undefined && variant.qty === 0 && (
                <div className="text-outOfStock text-sm font-dmsans leading-9 italic my-4">Sorry, our product is out of stock.</div>
              )}
              <div className="text-sm md:text-xs text-primary font-dmsans font-normal mt-4 capitalize">Quantity</div>
              <div className="mt-2 border-[0.5px] border-border border-solid py-1 px-2 flex justify-between items-center w-[115px] relative">
                {variant !== null && variant !== undefined && variant.qty === 0 && (
                  <div className="absolute top-0 left-0 z-10 w-full h-full bg-gray-300 opacity-50"></div>
                )}
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/minus.svg"}
                  alt="minus icon"
                  width={16}
                  height={16}
                  quality={100}
                  className="cursor-pointer"
                  onClick={minus_qty}
                />
                <input
                  type="text"
                  className="w-[60px] text-center text-black text-base h-4 outline-none"
                  value={qty}
                  onChange={e => {
                    if (variant !== null && variant !== undefined && variant.qty > 0) {
                      if (parseInt(e.currentTarget.value) < 1) {
                        setQty(1)
                      } else if (parseInt(e.currentTarget.value) <= variant.qty) {
                        setQty(parseInt(e.currentTarget.value))
                      } else {
                        setQty(variant.qty)
                      }
                    }
                  }}
                />
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/plus.svg"}
                  alt="plus icon"
                  width={16}
                  height={16}
                  quality={100}
                  className="cursor-pointer"
                  onClick={plus_qty}
                />
              </div>
            </div>
            {action === "add" ? (
              <div
                className={`w-full p-2 ${variant !== null && variant !== undefined && variant.qty === 0 ? "bg-listItem" : "bg-buttonBg cursor-pointer"} text-center text-white text-base font-dmsans font-normal mt-4`}
                onClick={add_to_cart}
              >
                Add To Cart
              </div>
            ) : (
              <div
                className={`w-full p-2 ${variant !== null && variant !== undefined && variant.qty === 0 ? "bg-listItem" : "bg-buttonBg cursor-pointer"} text-center text-white text-base font-dmsans font-normal mt-4`}
                onClick={update_to_cart}
              >
                Update
              </div>
            )}
          </div>
          <div className="hidden md:block rounded-lg bg-defaultBg w-[540px] h-[426px] py-4 px-8">
            <div className="flex justify-end mb-4">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cancel.svg"}
                alt="close icon"
                width={24}
                height={24}
                quality={100}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="grid h-full grid-cols-2 gap-8">
              <div className="col-span-1">
                <Image
                  src={displayImage}
                  width={800}
                  height={800}
                  alt={name + " gallery"}
                  quality={100}
                  className="w-full"
                />
              </div>
              <div className="max-h-[356px] col-span-1 overflow-y-auto">
                <div className="text-xs text-border font-dmsans font-normal">{product_code}</div>
                <div className="text-base text-primary font-dmsans font-bold leading-6 mt-2">{name}</div>
                <Attribute
                  product_code={props.product_code}
                  attribute_value_list={props.attribute_value_list}
                  product_variant_list={props.product_variant_list}
                  attributes={props.attributes}
                  style="popup"
                  variant={variant}
                  setVariant={setVariant}
                />
                {variant !== null && variant !== undefined && variant.qty === 0 && (
                  <div className="text-outOfStock text-sm font-dmsans leading-9 italic my-4">Sorry, our product is out of stock.</div>
                )}
                <div className="text-sm md:text-xs text-primary font-dmsans font-normal mt-4 capitalize">Quantity</div>
                <div className="mt-2 border-[0.5px] border-border border-solid py-1 px-2 flex justify-between items-center w-[160px] relative">
                  {variant !== null && variant !== undefined && variant.qty === 0 && (
                    <div className="absolute top-0 left-0 z-10 w-full h-full bg-gray-300 opacity-50"></div>
                  )}
                  <Image
                    src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/minus.svg"}
                    alt="minus icon"
                    width={14}
                    height={14}
                    quality={100}
                    className="cursor-pointer"
                    onClick={minus_qty}
                  />
                  <input
                    type="text"
                    className="w-[100px] text-center text-black text-base h-4 bg-defaultBg focus:outline-none"
                    value={qty}
                    onChange={e => {
                      if (variant !== null && variant !== undefined && variant.qty > 0) {
                        if (parseInt(e.currentTarget.value) < 1) {
                          setQty(1)
                        } else if (parseInt(e.currentTarget.value) <= variant.qty) {
                          setQty(parseInt(e.currentTarget.value))
                        } else {
                          setQty(variant.qty)
                        }
                      }
                    }}
                  />
                  <Image
                    src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/plus.svg"}
                    alt="plus icon"
                    width={14}
                    height={14}
                    quality={100}
                    className="cursor-pointer"
                    onClick={plus_qty}
                  />
                </div>
                {action === "add" ? (
                  <div
                    className={`w-full p-2 ${variant !== null && variant !== undefined && variant.qty === 0 ? "bg-listItem" : "bg-buttonBg cursor-pointer"} text-center text-white text-base font-dmsans font-normal mt-4`}
                    onClick={add_to_cart}
                  >
                    Add To Cart
                  </div>
                ) : (
                  <div
                    className={`w-full p-2 ${variant !== null && variant !== undefined && variant.qty === 0 ? "bg-listItem" : "bg-buttonBg cursor-pointer"} text-center text-white text-base font-dmsans font-normal mt-4`}
                    onClick={update_to_cart}
                  >
                    Update
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AddToCart
