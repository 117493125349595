import { useEffect, useState } from "react";
import Link from "next/link";
import Image from 'next/image';
import { CategoryItem } from "@/lib/constants";
import { useTopBannerStore } from "@/stores/useTopBannerStore";


const DesktopMenuItem = (props: CategoryItem) => {
  const { name, slug, subcategory, type } = props;
  const { topBanner, getTopBanner } = useTopBannerStore();
  const [isHover, setHover] = useState(false);
  const [hoverBrand, setHoverBrand] = useState("mooimom");
  const [topBannerData, setTopBannerData] = useState(getTopBanner());

  const handleHover = () => {
    setHover(true);
  }

  const handleLeave = () => {
    setHover(false);
  }

  const handleHoverBrand = (slug: string) => {
    setHoverBrand(slug);
  }

  useEffect(() => {
    setTopBannerData(getTopBanner());
  }, [topBanner, getTopBanner])

  return (
    <div className="flex items-center justify-center py-4 hoverable font-dmsans">
      {slug !== "brands" ? (
        <Link
          href={`/product-category/${slug}`}
          className=""
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
        >
          <p className="text-sm text-primary font-medium">
            {name}
          </p>
        </Link>
      ) : (
        <p
          className="text-sm text-primary font-medium"
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
        >
          {name}
        </p>
      )}
      <div className={`shadow-xl ${topBannerData.data.length === 0 || (topBannerData.data[0].name === null && topBannerData.data[0].desktop_image === null && topBannerData.data[0].mobile_image === null) ? "mega-menu" : "top-banner-mega-menu"}`}>
        {type === 'A' && (
          <div className="container w-full mx-auto">
            <hr className="border-t-[1px] border-solid" />
            <div className={`grid grid-cols-8 py-6 mt-2 mb-4 ${subcategory.filter(category_b => category_b.type === "BRAND").length === 0 ? "gap-x-20" : "gap-x-10"}`}>
              <div className={`flex ${subcategory.filter(category_b => category_b.type === "B" || category_b.type === "BRAND").length === 2 ? "justify-end space-x-20 col-span-3" : subcategory.filter(category_b => category_b.type === "B" || category_b.type === "BRAND").length === 3 ? "justify-between col-span-4" : "justify-between col-span-6"}`}>
                {subcategory.filter(category_b => category_b.type === "B" || category_b.type === "BRAND").map((category_b, index) => (
                  <div key={index}>
                    {category_b.type === "B" && (<p className="text-sm text-primaryText font-bold">{category_b.name}</p>)}
                    {category_b.type === "BRAND" && (
                      <Link href={`/brand/${category_b.slug}`}>
                        <div
                          className="flex items-center h-[50px]"
                          onMouseEnter={() => handleHoverBrand(category_b.slug)}
                        >
                          <Image
                            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-" + category_b.slug + "-left.png"}
                            alt={category_b.name + " image"}
                            width={500}
                            height={245}
                            quality={100}
                            className={`w-[150px]`}
                          />
                        </div>
                      </Link>
                    )}
                    <div className="text-sm text-primaryText font-normal mt-4">
                      <Link href={`/product-category/${slug}/${category_b.slug}`}>
                        Shop all
                      </Link>
                    </div>
                    {category_b.subcategory.map((category_c, index) => (
                      <div key={index} className="text-sm text-primaryText font-normal mt-4">
                        <Link href={`/product-category/${slug}/${category_b.slug}/${category_c.slug}`}>
                          {category_c.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {subcategory.filter(category_b => category_b.type === "BRAND").length === 0 ? (
                <div className={`flex space-x-4 ${subcategory.filter(category_b => category_b.type === "B").length > 0 ? "col-span-4" : "col-span-8"}`}>
                  {subcategory.filter(category_b => category_b.type === "Product Banner" && category_b.product_banner !== null).map((category_b, index) => (
                    <Link href={category_b.link !== null ? category_b.link : "/"} key={index}>
                      <div>
                        <Image
                          src={category_b.product_banner !== null ? category_b.product_banner : ""}
                          alt={category_b.name + " image"}
                          width={600}
                          height={623}
                          quality={100}
                        />
                        <p className="mt-4 text-sm text-primaryText font-dmsans font-medium text-center">{category_b.name}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                subcategory.filter(category_b => category_b.type === "BRAND").map((category_b, index) => (
                  <div key={index} className={`col-span-2`}>
                    <Link href={category_b.link !== null ? category_b.link : "/"}>
                      <Image
                        src={category_b.product_banner !== null ? category_b.product_banner : ""}
                        alt={category_b.name + " image"}
                        width={600}
                        height={623}
                        quality={100}
                      />
                      <p className="mt-4 text-sm text-primaryText font-dmsans font-medium text-center">{category_b.name}</p>
                    </Link>
                  </div>
                ))
              )}
              {subcategory.filter(category_b => category_b.type === "B" || category_b.type === "BRAND").length === 2 && (<div></div>)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DesktopMenuItem
