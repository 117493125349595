import { useEffect, useState } from 'react';
import Image from 'next/image';
import BrandName from './BrandName';
import CartProduct from './CartProduct';
import { useCartsStore } from '@/stores/useCartsStore';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import AddToCart from '../Product/AddToCart';
import { AddToCartProductItemProps, ProductVariantItemProps } from '../Product/constants';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getCalculatedResult, getNewToken } from '@/lib/Definitions';
import { useResultStore } from '@/stores/useResultStore';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { initResult } from '@/stores/initialState';
import { event } from 'nextjs-google-analytics';
import ActionDialog from '../Utils/ActionDialog';


interface CartProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const Cart = (props: CartProps) => {
  const { isVisible, setIsVisible } = props;
  const router = useRouter();
  const { attributes, getAttributes } = useAttributesStore();
  const [isOpen, setIsOpen] = useState(isVisible);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(null);
  const [showCart, setShowCart] = useState(false);
  const { carts, getCarts, updateCarts } = useCartsStore();
  const { userInfo, getUser, updateUser } = useUserStore();
  const { updateResult, getResult } = useResultStore();
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());
  const [calResultData, setCalResultData] = useState(getResult());
  const [attributesData, setAttributesData] = useState(getAttributes());
  const [open, setOpen] = useState(false);
  const [addToCartProp, setAddToCartProp] = useState<AddToCartProductItemProps | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<ProductVariantItemProps | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeCartId, setRemoveCartId] = useState(0);

  useEffect(() => {
    setIsOpen(isVisible)
  }, [setIsOpen, isVisible])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const confirm_remove_cart = (cart_id: number) => {
    setRemoveCartId(cart_id);
    setConfirmOpen(true);
  }

  const remove_cart = async (cart_id: number) => {
    if (cart_id > 0) {
      try {
        const response = await axios.delete(
          '/api/cart/' + cart_id,
          { headers: { Authorization: 'Bearer ' + userData.token } }
        )

        if (response.data.status === "ok") {
          const idx = cartsData.data.findIndex(cart => cart.id === cart_id);

          if (idx > -1) {
            event("remove_from_cart", {
              value: variant?.sales_price,
              currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
              items: [
                {
                  item_id: cartsData.data[idx].product_code,
                  item_name: cartsData.data[idx].name,
                  item_variant: variant?.product_code,
                  brand: cartsData.data[idx].brand,
                  price: variant?.special_price !== 0 ? variant?.special_price : cartsData.data[idx].special_price,
                  quantity: cartsData.data[idx].qty,
                  image: variant?.cart_image
                }
              ]
            });

            cartsData.data.splice(idx, 1);
            cartsData.update_date = Date.now();
            updateCarts(cartsData);
            setCartsData(cartsData);
          }
        }
      } catch (error: any) {
        if (error.response.data.error === "Invalid credentials") {
          const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

          if (response !== "") {
            userData.token = response.token;
            userData.refresh_token = response.refresh_token;
            updateUser(userData);
            remove_cart(cart_id);
          }
        }
      }
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    setAttributesData(getAttributes());
  }, [getAttributes, attributes])

  const show_add_to_cart = (cart_id: number) => {
    const idx = cartsData.data.findIndex(cart => cart.id === cart_id);

    if (idx > -1) {
      const cart = cartsData.data[idx];
      const filterVariant = cart.product_variant_list.filter((variant) => variant.variant_id === cart.product_variant_id);

      if (filterVariant.length > 0) {
        setSelectedVariant(filterVariant[0]);
      }

      if (addToCartProp !== null) {
        addToCartProp.id = cart.id;
        addToCartProp.name = cart.name;
        addToCartProp.msrp = cart.msrp;
        addToCartProp.sales_price = cart.sales_price;
        addToCartProp.product_code = cart.product_code;
        addToCartProp.qty = cart.qty;
        addToCartProp.image = cart.image;
        addToCartProp.special_price = cart.special_price;
        addToCartProp.attribute_value_list = cart.attribute_value_list;
        addToCartProp.product_variant_list = cart.product_variant_list;
        addToCartProp.brand = cart.brand;
        addToCartProp.slug = cart.slug;
        addToCartProp.attributes = attributesData.data;
        addToCartProp.style = "popup"
        addToCartProp.variant = selectedVariant
        addToCartProp.setVariant = setVariant
        addToCartProp.setShowCart = setShowCart
        addToCartProp.action = "update"
        addToCartProp.open = open
        addToCartProp.setOpen = setOpen

        setAddToCartProp(addToCartProp);
      } else {
        setAddToCartProp({
          id: cart.id,
          name: cart.name,
          msrp: cart.msrp,
          sales_price: cart.sales_price,
          product_code: cart.product_code,
          qty: cart.qty,
          image: cart.image,
          special_price: cart.special_price,
          attribute_value_list: cart.attribute_value_list,
          product_variant_list: cart.product_variant_list,
          brand: cart.brand,
          slug: cart.slug,
          attributes: attributesData.data,
          style: "popup",
          variant: selectedVariant,
          setVariant: setVariant,
          setShowCart: setShowCart,
          action: "update",
          open: open,
          setOpen: setOpen
        });
      }

      setOpen(true);
    }
  }

  const calculate_order = async () => {
    try {
      if (cartsData.data.length > 0) {
        const carts_data: any = [];

        cartsData.data.map((cart) => {
          carts_data.push({
            cart_id: cart.id,
            product_id: cart.product_id,
            variant_id: cart.product_variant_id,
            price: cart.special_price,
            qty: cart.qty,
          })
        });

        getCalculatedResult({
          data: {
            carts: carts_data,
            voucher: -1
          }
        }, 'Bearer ' + userData.token)
          .then((response) => {
            if (response.status === "ok") {
              updateResult(response);
              setCalResultData(response);
            }
          })
      } else {
        updateResult(initResult);
        setCalResultData(initResult);
      }
    } catch (error: any) {
      if (error.response.data.error === "Invalid credentials") {
        const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

        if (response !== "") {
          userData.token = response.token;
          userData.refresh_token = response.refresh_token;
          updateUser(userData);
          calculate_order();
        }
      }
    }
  }

  const render_cart_products = () => {
    let brand_list: Array<string> = [];

    return cartsData.data.sort((a, b) => a.brand.localeCompare(b.brand, 'en', { sensitivity: 'base' })).map((cart, index) => {
      if (brand_list.findIndex(brand => brand === cart.brand) === -1) {
        brand_list.push(cart.brand);

        return (
          <div key={index}>
            <BrandName brand_name={cart.brand} />
            <CartProduct
              id={cart.id}
              product_id={cart.product_id}
              product_code={cart.product_code}
              product_variant_id={cart.product_variant_id}
              brand={cart.brand}
              image={cart.image}
              image_alt={cart.image_alt}
              name={cart.name}
              slug={cart.slug}
              msrp={cart.msrp}
              sales_price={cart.sales_price}
              special_price={cart.special_price}
              qty={cart.qty}
              product_variant_list={cart.product_variant_list}
              attribute_value_list={cart.attribute_value_list}
              remove_cart={confirm_remove_cart}
              show_add_to_cart={show_add_to_cart}
              attributes={attributesData.data}
              calculate_order={calculate_order}
            />
          </div>
        )
      } else {
        return (
          <div key={index}>
            <CartProduct
              id={cart.id}
              product_id={cart.product_id}
              product_code={cart.product_code}
              product_variant_id={cart.product_variant_id}
              brand={cart.brand}
              image={cart.image}
              image_alt={cart.image_alt}
              name={cart.name}
              slug={cart.slug}
              msrp={cart.msrp}
              sales_price={cart.sales_price}
              special_price={cart.special_price}
              qty={cart.qty}
              product_variant_list={cart.product_variant_list}
              attribute_value_list={cart.attribute_value_list}
              remove_cart={confirm_remove_cart}
              show_add_to_cart={show_add_to_cart}
              attributes={attributesData.data}
              calculate_order={calculate_order}
            />
          </div>
        )
      }
    })
  }

  return (
    <>
      <div
        className={`${isOpen ? "fixed left-0 w-screen h-screen top-0 right-0 bottom-0 bg-black opacity-30 z-[54]" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsVisible(!isVisible);
        }}
      ></div>
      <div
        className={`fixed w-[90%] md:w-[468px] z-[55] bg-defaultBg top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 -right-[90%] md:-right-[468px] py-8 px-6 ${isOpen ? '-translate-x-full' : 'translate-x-0'}`}
      >
        <div className="flex justify-between">
          <div className="flex items-center">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cart.png"}
              alt="cart icon"
              quality={100}
              width={24}
              height={24}
              className=""
            />
            <p className="ml-4 text-sm font-bold text-black font-dmsans">{cartsData.data.length} items</p>
          </div>
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cancel.svg"}
            alt="close icon"
            width={24}
            height={24}
            quality={100}
            onClick={() => {
              setIsOpen(!isOpen);
              setIsVisible(!isVisible);
            }}
            className="cursor-pointer"
          />
        </div>
        <hr className="border-t-[1px] border-solid border-border mt-4 md:mt-8" />
        {cartsData.data.length > 0 ? (
          <>
            <div className="overflow-y-auto cart-product-height">
              {render_cart_products()}
            </div>
            <div
              className="mt-8 p-2 bg-buttonBg text-center text-white text-base font-dmsans font-normal cursor-pointer"
              onClick={() => {
                if (router.pathname === "/checkout") {
                  setIsOpen(!isOpen);
                  setIsVisible(!isVisible);
                } else {
                  router.push("/checkout");
                }
              }}
            >
              Checkout
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full cart-product-height">
            <div className="w-full">
              <div className="flex justify-center">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/cry-human.svg"}
                  alt="crying human icon"
                  width={80}
                  height={80}
                  quality={100}
                />
              </div>
              <p className="text-center text-base text-primary font-dmsans font-medium mt-4">Your cart is empty.</p>
              <Link href="/product-category/all-category/best-sellers">
                <div className="w-full py-4 px-12 bg-primary mt-[120px] text-base text-white font-dmsans font-medium text-center">
                  START SHOPPING
                </div>
              </Link>
            </div>
          </div>
        )}
        {addToCartProp !== null && <AddToCart
          id={addToCartProp.id}
          name={addToCartProp.name}
          msrp={addToCartProp.msrp}
          sales_price={addToCartProp.sales_price}
          product_code={addToCartProp.product_code}
          qty={addToCartProp.qty}
          image={addToCartProp.image}
          special_price={addToCartProp.special_price}
          attributes={attributesData.data}
          attribute_value_list={addToCartProp.attribute_value_list}
          product_variant_list={addToCartProp.product_variant_list}
          brand={addToCartProp.brand}
          slug={addToCartProp.slug}
          style="popup"
          variant={selectedVariant}
          setVariant={setVariant}
          setShowCart={setShowCart}
          action="update"
          open={open}
          setOpen={setOpen}
        />}
        <ActionDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          message="Are you sure you want to remove this item from cart?"
          action={() => {
            remove_cart(removeCartId)
            .then(() => {
              calculate_order();
            })
            .finally(() => {
              setConfirmOpen(false);
            });
          }}
        />
      </div>
    </>
  )
}

export default Cart
