import { useTopBannerStore } from "@/stores/useTopBannerStore"
import axios from "axios";
import { useEffect, useState } from "react";
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper";


const TopBanner = () => {
  const { updateTopBanner, getTopBanner } = useTopBannerStore();
  const [topBannerData, setTopBannerData] = useState(getTopBanner());

  const render_top_banner = () => {
    return topBannerData.data.map((item, index) => {
      if (item.desktop_image !== null && item.mobile_image !== null && item.image_alt !== null && item.name !== null) {
        return (
          <SwiperSlide key={index}>
            <div className="w-full hidden md:block relative">
              <Image src={item.desktop_image} alt={item.image_alt} width={0} height={0} quality={100} className="w-full h-auto" />
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-sm font-dmsans">
                {item.name}
              </p>
            </div>
            <div className="w-full md:hidden relative">
              <Image src={item.mobile_image} alt={item.image_alt} width={0} height={0} quality={100} className="w-full h-auto" />
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xs font-dmsans">
                {item.name}
              </p>
            </div>
          </SwiperSlide>
        )
      } else if (item.desktop_image !== null && item.mobile_image !== null && item.image_alt !== null) {
        return (
          <SwiperSlide key={index}>
            <div className="w-full hidden md:block">
              <Image src={item.desktop_image} alt={item.image_alt} width={0} height={0} quality={100} className="w-full h-auto" />
            </div>
            <div className="w-full md:hidden">
              <Image src={item.mobile_image} alt={item.image_alt} width={0} height={0} quality={100} className="w-full h-auto" />
            </div>
          </SwiperSlide>
        )
      } else if (item.name !== null) {
        return (
          <SwiperSlide key={index} className="w-full text-center text-xs md:text-sm font-dmsans text-white py-[15px]">
            {item.name}
          </SwiperSlide>
        )
      } else {
        return (<></>);
      }
    })
  }

  useEffect(() => {
    const fetchTopBanner = async () => {
      try {
        const response = await axios.get('/api/topbanner')

        if (response.data.status === "ok") {
          topBannerData.update_date = Date.now();

          if (response.data.data.length > 0) {
            topBannerData.data = response.data.data;
          } else {
            topBannerData.data = [];
          }

          updateTopBanner(topBannerData);
          setTopBannerData(topBannerData);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (topBannerData.update_date === null || topBannerData.update_date < Date.now() - 600000 || topBannerData.data === undefined) {
      fetchTopBanner();
    }
  }, [topBannerData, topBannerData.update_date, updateTopBanner])

  if (topBannerData.data !== undefined && topBannerData.data.length > 0 && topBannerData.data[0].name === null && topBannerData.data[0].desktop_image === null && topBannerData.data[0].mobile_image === null) {
    return (<></>);
  }

  return (
    <>
      {topBannerData.data !== undefined && topBannerData.data.length > 0  && (
        <div className="w-full h-[50px] bg-secondary">
          <Swiper
            direction="vertical"
            loop={true}
            autoplay={{ delay: 3000 }}
            speed={1200}
            modules={[Autoplay]}
            className="top-banner-swiper"
          >
            {render_top_banner()}
          </Swiper>
        </div>
      )}
    </>
  )
}

export default TopBanner
