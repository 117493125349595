import Link from "next/link";
import Image from 'next/image';
import { CategoryItem } from "@/lib/constants";
import { useState } from "react";
import { motion } from "framer-motion";


interface CategoryListItemProps {
  categories: Array<CategoryItem>
}

const MobileMenuItem = (props: CategoryListItemProps) => {
  const { categories } = props;
  const [showMenu, setShowMenu] = useState<number>(0)

  const handleShowMenu = (id: number) => {
    if (showMenu === id) {
      setShowMenu(0);
    } else {
      setShowMenu(id);
    }
  }

  return (
    <div className="w-full overflow-y-auto mega-menu-height font-dmsans">
      <div className="container">
        {categories.filter(category => category.type === "A").map((category, index) => {
          if (category.subcategory.filter(category_b => category_b.type === "B" || category_b.type === "BRAND").length === 0) {
            return (
              <div className="py-5 border-b border-border" key={index}>
                <div
                  className="flex items-center justify-between text-base leading-6"
                  onClick={() => handleShowMenu(category.id)}
                >
                  <p>{category.name}</p>
                  {showMenu === category.id ? (
                    <Image
                      src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/minus.svg"}
                      alt="minus icon"
                      width={14}
                      height={14}
                      quality={100}
                      className="cursor-pointer"
                    />
                  ) : (
                    <Image
                      src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/plus.svg"}
                      alt="plus icon"
                      width={14}
                      height={14}
                      quality={100}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                <motion.div
                  layout
                  style={{ height: showMenu !== category.id ? "0px" : "100%"}}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  {category.subcategory.filter(category_b => category_b.type != "B" && category_b.type != "BRAND").map((category_b, index) => (
                    <Link href={category_b.link !== null ? category_b.link : "/"} key={index}>
                      <div className="mx-2">
                        <p className="mt-4 text-sm text-primaryText">{category_b.name}</p>
                      </div>
                    </Link>
                  ))}
                </motion.div>
              </div>
            )
          } else if (category.subcategory.filter(category_b => category_b.type === "BRAND").length > 0) {
            return (
              <div className="py-5" key={index}>
                <div className="flex items-center justify-between text-base font-bold leading-6">
                  {category.name}
                </div>
                {category.subcategory.filter(category_b => category_b.type === "BRAND").map((category_b, index) => (
                  <div className="" key={index}>
                    <div
                      className="flex items-center justify-between mx-2 mt-6 text-base font-normal"
                      onClick={() => handleShowMenu(category_b.id)}
                    >
                      <p>{category_b.name.split("-", 1)}</p>
                      {showMenu === category_b.id ? (
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/minus.svg"}
                          alt="minus icon"
                          width={14}
                          height={14}
                          quality={100}
                          className="cursor-pointer"
                        />
                      ) : (
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/plus.svg"}
                          alt="plus icon"
                          width={14}
                          height={14}
                          quality={100}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                    <motion.div
                      layout
                      style={{ height: showMenu !== category_b.id ? "0px" : "100%"}}
                      transition={{ duration: 0.3 }}
                      className="mx-4 overflow-hidden"
                    >
                      <Link href={`/product-category/${category.slug}/${category_b.slug}`}>
                        <p className="mt-6 text-sm text-primaryText" key={index}>Shop All</p>
                      </Link>
                      {category_b.subcategory.map((category_c, index) => (
                        <Link href={`/product-category/${category.slug}/${category_b.slug}/${category_c.slug}`} key={index}>
                          <p className="mt-6 text-sm text-primaryText">{category_c.name}</p>
                        </Link>
                      ))}
                    </motion.div>
                  </div>
                ))}
                <div className="grid grid-cols-2 mt-5 gap-x-6">
                  {category.subcategory.filter(category_b => category_b.type === "BRAND").map((category_b, index) => (
                    <div className="w-full min-h-[100px] flex items-center justify-center" key={index}>
                      <Link href={"/brand/" + category_b.slug}>
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-" + category_b.slug + "-middle.png"}
                          alt={category_b.name + " image"}
                          width={500}
                          height={245}
                          quality={100}
                          className={`w-[150px]`}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )
          } else {
            return (
              <div className="py-5 border-b border-border" key={index}>
                <div className="text-lg font-bold leading-6">
                  {category.name}
                </div>
                {category.subcategory.filter(category_b => category_b.type === "B").map((category_b, index) => (
                  <div className="" key={index}>
                    <div
                      className="flex items-center justify-between mx-2 mt-6 text-base font-normal"
                      onClick={() => handleShowMenu(category_b.id)}
                    >
                      <p>{category_b.name}</p>
                      {showMenu === category_b.id ? (
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/minus.svg"}
                          alt="minus icon"
                          width={14}
                          height={14}
                          quality={100}
                          className="cursor-pointer"
                        />
                      ) : (
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/plus.svg"}
                          alt="plus icon"
                          width={14}
                          height={14}
                          quality={100}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                    <motion.div
                      layout
                      style={{ height: showMenu !== category_b.id ? "0px" : "100%"}}
                      transition={{ duration: 0.3 }}
                      className="mx-4 overflow-hidden"
                    >
                      <Link href={`/product-category/${category.slug}/${category_b.slug}`}>
                        <p className="mt-6 text-sm text-primaryText" key={index}>Shop All</p>
                      </Link>
                      {category_b.subcategory.map((category_c, index) => (
                        <Link href={`/product-category/${category.slug}/${category_b.slug}/${category_c.slug}`} key={index}>
                          <p className="mt-6 text-sm text-primaryText">{category_c.name}</p>
                        </Link>
                      ))}
                    </motion.div>
                  </div>
                ))}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default MobileMenuItem
